body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*::-webkit-scrollbar {
  display: block;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #dedede;
}
.Mui-disabled {
  opacity: 0.6;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 8px;
}

* {
  outline: none !important;
}

.MuiDrawer-paperAnchorDockedLeft {
  border-right: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.MuiListItem-button {
  height: 57px;
}

.MuiListItem-root.Mui-selected {
  /* background: linear-gradient(
    60deg,
    rgb(72, 20, 244) -30%,
    rgb(247, 90, 160) 50%,
    rgb(254, 174, 110) 130%
  ); */
  /* background: rgba(72, 20, 242, 0.15); */
  /* background: #fff; */
}
.MuiListItem-root.Mui-selected:hover {
  /* background: linear-gradient(
    60deg,
    rgb(72, 20, 244) -30%,
    rgb(247, 90, 160) 50%,
    rgb(254, 174, 110) 130%
  ); */
  /* background: rgba(72, 20, 242, 0.15); */
  /* background-color: rgb(107, 20, 20); */
}
.MuiListItem-root {
  background-color: transparent !important;
}
.MuiListItem-root.Mui-selected * {
  color: #ff5078 !important;
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: transparent !important;
}
.MuiListItemIcon-root svg {
  color: #3e3e46;
}
.MuiListItemText-root span {
  color: #3e3e46;
}
.MuiDivider-root {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.makeStyles-toolbar-10 {
  justify-content: flex-start !important;
}
.MuiFormLabel-root.Mui-focused {
  color: #ff5078 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #ff5078 !important;
}
.clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 65px;
}
.MuiCardContent-root:last-child {
  padding-bottom: 8px !important;
}
.MuiFormHelperText-root.Mui-error {
  color: #f44336;
  position: absolute;
  bottom: -24px;
  width: 290px;
  left: -15px;
  font-size: 11px;
}
.MuiPickersToolbar-toolbar {
  background: linear-gradient(
    60deg,
    rgb(72, 20, 244) -30%,
    rgb(247, 90, 160) 50%,
    rgb(254, 174, 110) 130%
  );
}
.MuiPickersDay-daySelected {
  background: linear-gradient(
    60deg,
    rgb(72, 20, 244) -30%,
    rgb(247, 90, 160) 50%,
    rgb(254, 174, 110) 130%
  );
}
.MuiButton-textPrimary {
  color: #ff5078 !important;
}
.MuiFormControl-root {
  min-width: 200px !important;
}
.MuiTableCell-root {
  font-size: 12px !important;
  /* padding: 12px !important; */
  white-space: nowrap;
}

.MuiTableCell-head {
  font-size: 14px !important;
  color: #aeaeae !important;
  font-weight: 500 !important;
}

.MuiTableCell-body {
  font-size: 12px !important;
  font-weight: 500;
  color: #404040 !important;
}

.MuiTypography-body1 {
  font-size: 16px !important;
  font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  color: #000000 !important;
  letter-spacing: 0.00938em;
}

/* @media (min-width: 1600px) {
  .MuiTableCell-root {
    padding: 12px !important;
    font-size: 12px !important;
  }
} */
